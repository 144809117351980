<template>
    <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li class="flex">
            <div class="flex items-center">
            <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <router-link to="/dashboard/products" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł produkty</router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Kategorie produktów</div>
            </div>
        </li>
        </ol>
    </nav>
    <router-link to="/dashboard/products" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

    <div class="md:mt-4">
        <div class="mb-4">
            <div class="mt-1 flex w-full sm:4/5 md:w-3/5 lg:w-2/5 rounded-md">
                <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"> Nazwa </span>
                <input type="text" maxlength="40" v-model="newCategoryName" class="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md border focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300" placeholder="Wpisz nazwę kategorii" />
                <button type="button" @click.prevent="addNewCategory" :disabled="newCategoryName.length === 0 || this.disabledAddButton"  :class="{'transition ease-in-out duration-300 ml-4 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500': true, 'opacity-40 cursor-default': newCategoryName.length === 0 || this.disabledAddButton, 'hover:bg-blue-700': newCategoryName.length > 0 }">Utwórz kategorię</button>
            </div>       
        </div>

        <div>
            <div :class="['flex justify-between items-center', categories.length > 0 ? '' : 'mt-6 mb-4']">
                <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Dostępne kategorie:</h2>
                <div v-show="categories.length > 0" class="flex items-center py-1.5">
                        <Switch v-model="enabledEdit" class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span class="sr-only">Use setting</span>
                            <span aria-hidden="true" class="pointer-events-none absolute w-full h-full rounded-md" />
                            <span aria-hidden="true" :class="[enabledEdit ? 'bg-blue-600' : 'bg-gray-200', 'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200']" />
                            <span aria-hidden="true" :class="[enabledEdit ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200']" />
                        </Switch>
                        <div @click="enabledEdit = !enabledEdit" class="text-xs cursor-pointer text-gray-400 ml-2"> Edycja </div>
                </div>
            </div>
            <div v-show="categories.length > 0" class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                <div v-for="(category, catIdx) in categories" :key="catIdx" class="relative flex justify-between items-center py-4">
                    <div>
                        <div class="flex flex-1 text-sm">
                            <div class="w-14">
                                <span class="text-xs text-gray-400">ID:{{category.id}}</span>
                            </div>
                            <!-- <div class="flex-shrink-0 mr-4">
                                <div class="h-10 w-10 rounded-full text-white bg-yellow-400 flex flex-col justify-center items-center text-xs">
                                    <span>ID</span>
                                    <span class="font-medium">{{category.id}}</span>
                                </div>
                            </div> -->
                            <div class="flex items-center">
                                <label :for="`category-${category.id}`" class="font-medium text-sm text-gray-700 select-none cursor-pointer"> {{ category.name }} </label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="ml-3 flex justify-end items-center h-5" v-if="enabledEdit">
                        <input :id="`category-${category.id}`" :key="rerender" @change="selectOption(category.id,category.name)" :name="`category-${category.id}`" type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded cursor-pointer" />
                    </div> -->
                    <div v-if="enabledEdit">
                        <button @click.prevent="deleteOption(category.id,category.name)" class="transition ease-in-out duration-300 inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-red-50 hover:text-red-500 hover:border-red-200"> Usuń </button>
                        <!-- <button @click.prevent="deleteOption(category.id,category.name)" type="button" class="inline-flex items-center rounded border border-red-400 bg-red-500 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">Delete <span v-show="selected.length>1"> ({{selected.length}}) </span></button> -->
                    </div>
                </div>
            </div>
        </div>
        <emptyState v-show="categories.length === 0"></emptyState>
        <askForDelete v-if="deleteAsk" :modalData="modalData" @accept="deleteFromDatabase" @closeModal="deleteAsk = false"></askForDelete>
    </div>
</template>

<script>
    import emptyState from '@/components/EmptyState.vue';
    //import axios from 'axios';
    import moment from 'moment';
    import {db} from "@/firebase/gfbconf.js";
    import { Switch } from '@headlessui/vue'
    import askForDelete from '@/components/AlertModal.vue';
    import { HomeIcon } from '@heroicons/vue/solid'

    export default {
        name: "Categories",
        components: {HomeIcon, emptyState, askForDelete, Switch},
        data()
        {
            return {
                enabledEdit: false,
                rerender: 0,
                deleteAsk: false,
                dbListener: null,
                newCategoryName: '',
                disabledAddButton: false,
                categories: [],
                selected: [],
                modalData: {
                  headerText: "Usuwanie kategorii",
                  bodyText: "Czy na pewno chcesz usunąć wybraną kategorię? Dane zostaną permamentnie usunięte.",
                  acceptButtonText: "Usuń"
                }
            }
        },
        created()
        {
            if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.products)
            {
                this.$router.push("/hub")
            }
            this.dbListener = db.collection("Categories").orderBy("creationDate", "desc")
            .onSnapshot((querySnapshot) => {
                this.categories = [];
                querySnapshot.forEach((doc) => {
                    this.categories.push(doc.data());
                });
            });
        },
        methods:
        {
            async deleteFromDatabase()
            {
                for(let i=0;i<this.selected.length;i++)
                {
                    await db.collection("Categories").doc(this.selected[i].toString()).delete();
                }
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Kategoria została usunięta!",
                    subheader: `Kategoria nie będzie już dostępna.`,
                    success: true
                }); 
                this.selected = [];
                this.rerender++;
            },
            selectOption(id)
            {
                if(!this.selected.includes(id))
                {
                    this.selected.push(id);
                }
                else
                {
                    this.selected.splice(this.selected.indexOf(id),1);
                }
            },
            deleteOption(id)
            {
                this.selected = []
                this.selected.push(id);
                this.deleteAsk = true
            },
            async calculateId()
            {
                const newId = Math.floor(Math.random() * 1000)+1;
                const freeId = await db.collection("Categories").doc(newId.toString()).get();
                return {
                    id: newId,
                    exists: freeId.exists
                }
            },
            async addNewCategory()
            {
                if(this.newCategoryName.length === 0)
                {
                    return 0;
                }
                this.disabledAddButton = true
                try {
                    let freeId = null;
                    for(let i=0; i < 999; i++)
                    {
                        const resultOfGettingId = await this.calculateId();
                        if(!resultOfGettingId.exists)
                        {
                            freeId = resultOfGettingId;
                            break;
                        }
                    }
                    if(freeId !== null)
                    {
                        await db.collection("Categories").doc(freeId.id.toString()).set({
                            name: this.newCategoryName,
                            id: parseInt(freeId.id),
                            creationDate: moment().toDate(),
                            createdBy: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                            products: []
                        });
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Kategoria została utworzona!",
                            subheader: `Kategoria ${this.newCategoryName} została dodana do listy.`,
                            success: true
                        }); 
                        this.disabledAddButton = false
                        this.newCategoryName = "";
                    }
                    else
                    {
                        this.$store.commit("resetGlobalLoader");
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Kategoria nie może zostać utworzona!",
                            subheader: `Nie umiem odnaleźć wolnego ID.`,
                            success: false
                        });
                        this.disabledAddButton = false                      
                    }
                }
                catch(error)
                {
                    this.$store.commit("resetGlobalLoader");
                    console.log(error);
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Kategoria nie może zostać utworzona!",
                        subheader: `Coś poszło nie tak..`,
                        success: false
                    });   
                }
            }
        },
        watch: {
            enabledEdit()
            {
                this.selected = [];
            }
        },
        beforeUnmount() {
            if(this.dbListener !== null){
                this.dbListener();
            }
        }
    }
</script>
<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>