<template>
    <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li class="flex">
            <div class="flex items-center">
            <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <router-link to="/dashboard/storage" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł magazyn</router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Zarządzanie magazynami</div>
            </div>
        </li>
        </ol>
    </nav>
    <router-link to="/dashboard/storage" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

<div class="md:mt-4">
    <Transition name="fade">
    <div v-if="enabledEdit" class="mb-4">
        <div class="mt-1 flex w-full sm:4/5 md:w-3/5 lg:w-2/5 rounded-md">
            <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"> Nazwa </span>
            <input type="text" maxlength="40" v-model="newWarehouseName" class="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md border focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300" placeholder="Wpisz nazwę magazynu" />
            <button type="button" @click.prevent="addNewWarehouse" :disabled="newWarehouseName.length === 0 || this.disabledAddButton"  :class="{'transition ease-in-out duration-300 ml-4 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500': true, 'opacity-40 cursor-default': newWarehouseName.length === 0 || this.disabledAddButton, 'hover:bg-blue-700': newWarehouseName.length > 0 }">Utwórz magazyn</button>
        </div>       
    </div>
    </Transition>

        <div class="flex justify-end">
            <button @click.prevent="generateCSV" type="button" class="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-100 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200 transition-all duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <DocumentDownloadIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Wygeneruj plik CSV
            </button>
        </div>

        <div :class="['flex justify-between items-center', warehouses.length > 0 ? 'mt-4' : 'mt-6 mb-4']">
            <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Dostępne magazyny:</h2>
            <div class="flex flex-row-reverse">
                <div v-if="isEditable" class="flex items-center py-1.5">
                    <Switch v-model="enabledEdit" class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span class="sr-only">Use setting</span>
                        <span aria-hidden="true" class="pointer-events-none absolute w-full h-full rounded-md" />
                        <span aria-hidden="true" :class="[enabledEdit ? 'bg-blue-600' : 'bg-gray-200', 'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200']" />
                        <span aria-hidden="true" :class="[enabledEdit ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200']" />
                    </Switch>
                    <div @click="enabledEdit = !enabledEdit" class="text-xs cursor-pointer text-gray-400 ml-2"> Edycja </div>
                </div>
            </div>
        </div>

        <div v-show="warehouses.length > 0" class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
            <div v-for="(category, catIdx) in warehouses" :key="catIdx" class="relative items-center py-4">
                <div class="flex justify-between items-center">
                    <div class="flex flex-1 text-sm">
                        <div class="w-14">
                            <span class="text-xs text-gray-400">ID:{{category.id}}</span>
                        </div>
                        <div class="flex items-center">
                            <label :for="`category-${category.id}`" class="font-medium text-sm text-gray-700 select-none cursor-pointer"> {{ category.name }} </label>
                        </div>
                    </div>
                    <div class="flex gap-2 items-center">
                        <div v-if="!enabledEdit">
                            <div @click.prevent="printLabelWarehouse(category)" v-tooltip="`Wydrukuj PDF z QR kodem magazynu.`" class="pr-2">
                                <QrcodeIcon class="w-8 h-8 text-gray-400 hover:text-gray-600 transition-all duration-300 cursor-pointer" />
                            </div>  
                            <!-- <ChevronDownIcon @click="openStorage(category, catIdx)" :class="[this.toggleStorage[catIdx].open ? 'transform rotate-180' : '', ' transition-all duration-300 cursor-pointer w-5 h-5 mr-5 text-gray-400 hover:text-gray-600']" /> -->
                        </div>
                        <div v-if="enabledEdit">
                            <button @click.prevent="deleteOption(category.id), this.deleteType = 'warehouse'" class="transition ease-in-out duration-300 inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-xs leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-red-50 hover:text-red-500 hover:border-red-200">
                                Usuń
                            </button>
                        </div>
                        <ChevronDownIcon @click="openStorage(category, catIdx), getAllSegmentsFromWarehouse(category.id)" :class="[this.toggleStorage[catIdx].open ? 'transform rotate-180' : '', ' transition-all duration-300 cursor-pointer w-5 h-5 text-gray-400 hover:text-gray-600']" />
                    </div>
                </div>
                <div class="mt-2" v-if="this.toggleStorage[catIdx].open">
                    <div class="relative">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-300" />
                        </div>
                        <div class="relative flex justify-center">
                            <span class="bg-gray-100 px-2 text-gray-500">
                                <ChevronDownIcon class="h-5 w-5 text-gray-500 " aria-hidden="true" />
                            </span>
                        </div>
                    </div>
                    <div class="mt-4 bg-white p-4 rounded-lg shadow-lg">
                        <Transition name="fade">
                        <div v-if="enabledEdit" class="flex max-w-md rounded-md">
                            <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">Nazwa</span>
                            <input type="text" maxlength="40" v-model="newSegmentName" class="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md border focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300" placeholder="Wpisz nazwę segmentu" />
                            <button type="button" @click.prevent="addNewSegment(category.id)" :disabled="newSegmentName.length === 0 || this.disabledAddButton"  :class="{'transition ease-in-out duration-300 ml-4 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500': true, 'opacity-40 cursor-default': newSegmentName.length === 0 || this.disabledAddButton, 'hover:bg-blue-700': newSegmentName.length > 0 }">Utwórz segment</button>
                        </div> 
                        </Transition>
                        <div class="min-w-0 flex-1 xl:col-span-6">
                            <div :class="{'w-full flex': true, 'justify-between mb-4': true, 'flex-col space-y-4 lg:space-y-0 lg:flex-row': this.showStatusFilters}">
                                <Transition name="fade">
                                <div v-if="showStatusFilters === true" class="mr-4 flex flex-col lg:flex-row w-full justify-between lg:items-center lg:space-x-3 space-y-4 lg:space-y-0 select-none">
                                    <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0">
                                        <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                            <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 rounded-t-md">Sortowanie segmentów</label>
                                            <select @change="changeSort()" v-model="sortName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                                <option value="creationDesc">Utworzono: Od najnowszych</option>
                                                <option value="creationAsc">Utworzono: Od najstarszych</option>
                                            </select>
                                        </div>
                                        <button type="button" @click="this.pickStatus(selectedStorage)" :class="[this.selectedStorage !== null ? '' : 'pointer-events-none opacity-20', 'transition ease-in-out duration-300 inline-flex items-center justify-center rounded border border-transparent bg-blue-100 px-2.5 py-2 lg:py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">
                                            Zastosuj
                                        </button>
                                    </div>
                                </div>
                                </Transition>
                                <div :class="{'flex items-center justify-end w-full gap-2 transition-all duration-300': true, 'min-w-filters': showStatusFilters}">
                                    <Transition name="fade">
                                        <RefreshIcon v-if="unlockRefresh" @click.prevent="refreshData(category.id)" class="w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer" />
                                    </Transition>
                                    <div @click="showFilters(selectedStorage)" class="mx-4 mt-1 cursor-pointer flex justify-center items-center flex-col select-none">
                                        <FilterIcon class="h-4 w-4 text-blue-500 flex-shrink-0" aria-hidden="true" />
                                        <span class="text-xs mt-1 font-medium text-blue-700">{{showStatusFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Dostępne segmenty:</h2>
                        <div class="py-2">
                            <div v-auto-animate v-if="segments.length > 0" class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                                <div v-for="(ctgory, idx) in segments" :key="idx" :class="['relative items-center', ctgory.meta.lastModificationDate != null ? 'pt-4' : 'py-4']">
                                    <div class="flex justify-between items-center">
                                        <div class="flex flex-1 text-sm gap-2">
                                            <div class="w-14">
                                                <span class="text-xs text-gray-400">ID:{{ctgory.id}}</span>
                                            </div>
                                            <div class="flex items-center">
                                                <label :for="`ctgory-${ctgory.id}`" class="font-medium text-sm text-gray-700 select-none cursor-pointer"> {{ ctgory.name }} </label>
                                            </div>
                                        </div>
                                        <div class="flex items-center gap-2">
                                            <QrcodeIcon @click.prevent="printLabelSegment(ctgory, category)" v-tooltip="`Wydrukuj PDF z QR kodem segmentu.`" class="w-8 h-8 text-gray-400 hover:text-gray-600 transition-all duration-300 cursor-pointer" />
                                            <ChevronDownIcon @click="openSegments(ctgory, idx, category), openAdditionalProducts = false;" :class="[this.toggleSegment[idx].open ? 'transform rotate-180' : '', ' transition-all duration-300 cursor-pointer w-5 h-5 text-gray-400 hover:text-gray-600']" />
                                            <div v-if="enabledEdit">
                                                <button @click.prevent="deleteOption(category.id, ctgory.id), this.deleteType = 'segment'" class="transition ease-in-out duration-300 inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-xs leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-red-50 hover:text-red-500 hover:border-red-200">Usuń</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="ctgory.meta.lastModificationDate != null" class="flex justify-center items-center gap-1 py-1 opacity-50 select-none">
                                        <span class="text-gray-500 uppercase font-semibold text-xs">Ostatnia data przyjęcia/wydania:</span>
                                        <span class="text-xs text-indigo-600 font-semibold">{{moment.unix(ctgory.meta.lastModificationDate).format('LLL')}}</span>
                                    </div>
                                    <Transition name="fade">
                                    <div v-auto-animate class="mt-2" v-if="this.toggleSegment[idx].open">
                                        <div class="relative">
                                            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div class="w-full border-t border-gray-300" />
                                            </div>
                                            <div class="relative flex justify-center">
                                                <span class="bg-white px-2 text-gray-500">
                                                    <ChevronDownIcon class="h-5 w-5 text-gray-500 " aria-hidden="true" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="py-16 bg-gray-200 shadow-xl rounded-xl" v-if="loading">
                                            <LoadingSpinnerHub  />
                                        </div>
                                        
                                        <div v-auto-animate class="mt-2 py-6 bg-gray-200 shadow-xl rounded-xl px-4" :class="[this.toggleSegment[idx].open ? 'mb-2' : '']" v-if="!loading">
                                            <h2 class="text-sm font-semibold text-gray-500 uppercase">Aktualnie dodane produkty</h2>
                                            <ul role="list" class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                                            <li v-for="(prd, index) in Object.keys(this.products)" :key="index" class="col-span-1 flex rounded-md shadow-sm">
                                                <div :class="['flex bg-purple-400 w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">
                                                    <img :src="`https://api.dicebear.com/7.x/initials/svg?seed=${this.products[prd].product.name}&scale=50`" alt="">
                                                </div>
                                                <div class="truncate flex flex-1 items-center justify-between rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                                <div class="flex-1 truncate px-4 py-2 text-sm">
                                                    <a class="font-medium text-gray-900 hover:text-gray-600">{{this.products[prd].product.name}}</a>
                                                    <p class="text-gray-500">{{createCustomNameByLength(Object.keys(this.products[prd].parts).length)}}</p>
                                                </div>
                                                <div class="flex-shrink-0 pr-2">
                                                    <button v-if="!this.products[prd].product.isDeleted" @click.prevent="openAdditionalInformation(index, this.products[prd].parts)" type="button" class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                    <span class="sr-only">Open options</span>
                                                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                                    </button>
                                                    <button v-else @click.prevent="removeFromStorage(null, this.products[prd].product, null, 'product')" type="button" class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                    <!-- <button v-else @click.prevent="deleteProductFromStorage(this.products[prd].product)" type="button" class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"> -->
                                                    <span class="sr-only">Open options</span>
                                                        <TrashIcon class="h-5 w-5" aria-hidden="true" />
                                                    </button>
                                                </div>
                                                </div>
                                            </li>
                                            </ul>

                                            <Transition name="fade">
                                            <div v-if="openAdditionalProducts">
                                                <div class="relative mt-6">
                                                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                                        <div class="w-full border-t border-gray-300" />
                                                    </div>
                                                    <div class="relative flex justify-center">
                                                        <span class="bg-gray-200 px-2 text-gray-500">
                                                            <ChevronDownIcon class="h-5 w-5 text-gray-500 " aria-hidden="true" />
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="py-4">
                                                    <div v-auto-animate="{ duration: 600 }" class="mx-auto max-w-7xl overflow-hidden">
                                                    <h2 class="sr-only">Products</h2>

                                                    <div class="-mx-px grid grid-cols-2 border-l border-gray-200 sm:mx-0 md:grid-cols-3 lg:grid-cols-4 gap-2">
                                                        <div v-for="(product, index) in Object.keys(this.selectedProd)" :key="index" class="bg-white shadow-xl rounded-xl group border-t relative border-b border-r border-gray-200 p-4 sm:p-6">
                                                            <div class="flex items-center">
                                                                <!-- <img class="w-10 h-10 bg-transparent mix-blend-multiply	" :src="`${this.selectedProd[product].variant.image.url}`" alt=""> -->
                                                                <img class="w-10 h-10 bg-transparent mix-blend-multiply	" :src="this.selectedProd[product].variant.image.url.length>0 ? this.selectedProd[product].variant.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`${this.selectedProd[product].variant.name}`}`" alt="">
                                                                <label class="uppercase p-2 block text-xs font-semibold text-indigo-600">{{this.selectedProd[product].variant.name}}</label>
                                                            </div>
                                                            <div class="flex justify-between select-none">
                                                                <span class="uppercase p-2 block text-xs font-semibold text-gray-900">Paczka</span>
                                                                <span class="uppercase p-2 block text-xs font-semibold text-gray-900">Ilość</span>
                                                                <span v-if="$store.state.userData.permissions.admin || $store.state.userData.permissions.storageDelete" class="uppercase p-2 block text-xs font-semibold text-red-300">Usuń</span>
                                                            </div>
                                                            <div class="px-2">
                                                                <div v-for="(prt, index) in this.selectedProd[product]" :key="index">
                                                                    <div v-if="index !== 'variant'">
                                                                        <div class="flex justify-between text-sm">
                                                                            <span>{{prt.part}}</span>
                                                                            <span>{{prt.quantity}} szt.</span>
                                                                            <TrashIcon v-if="$store.state.userData.permissions.admin || $store.state.userData.permissions.storageDelete" v-tooltip="`Usuń paczkę z segmentu`" @click.prevent="removeFromStorage(prt, product, this.selectedProd[product], 'part')" class="cursor-pointer text-red-300 w-6 h-6 hover:text-red-500 transition-all duration-300" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </Transition>

                                            <div v-if="Object.keys(this.products).length === 0" class="select-none relative block w-full border-2 border-gray-400 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                                <CubeTransparentIcon class="mx-auto h-12 w-12 text-gray-400" />
                                                <span class="mt-2 block text-sm font-medium text-gray-400"> W tym segmencie nie znajdują się produkty...</span>
                                            </div>
                                        </div>

                                    </div>
                                    </Transition>
                                </div>
                            </div>
                            <div class="flex items-center justify-center mt-4" v-if="!queryLimitReached && segments.length > 0">
                                <button type="button" @click.prevent="loadMoreSegments(category.id), openAdditionalProducts = false, latestSegment = null" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                    Załaduj więcej...
                                </button>
                            </div>
                        </div>
                        <EmptyState v-if="segments.length === 0" />
                    </div>
                </div>
            </div>
        </div>
    <EmptyState v-show="warehouses.length === 0" />
</div>
<askForDelete v-if="deleteAsk" :modalData="modalData" @accept="deleteFromDatabase" @closeModal="deleteAsk = false" />
<AlertPackageModal v-if="alertModal.open" @closeModal="cancelRemoveFromStorage" @accept="deletePackage" :modalData="alertModal.data" buttonColor="indigo" iconColor="indigo" />
<AlertDeleteProductModal v-if="alertModalProductDelete.open" @closeModal="cancelRemoveProductFromStorage" @accept="deleteProductFromStorage" :modalData="alertModalProductDelete.data" buttonColor="indigo" iconColor="indigo" />
</template>

<script>
import { db } from "@/firebase/gfbconf.js";
import { Switch, Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { HomeIcon, QrcodeIcon, TrashIcon, RefreshIcon, DocumentDownloadIcon, CubeTransparentIcon } from '@heroicons/vue/solid'
import { ChevronDownIcon, FilterIcon } from '@heroicons/vue/outline'
import axios from 'axios';
import moment from 'moment';
import randomstring from 'randomstring';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import EmptyState from '@/components/EmptyState.vue';
import askForDelete from '@/components/AlertModal.vue';
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import AlertPackageModal from '../components/AlertPackageModal.vue';
import AlertDeleteProductModal from '../components/AlertDeleteProductModal.vue';
export default {
   name: "Segments",
   components: {
    AlertDeleteProductModal, CubeTransparentIcon, DocumentDownloadIcon, AlertPackageModal, RefreshIcon, FilterIcon, HomeIcon, QrcodeIcon,TrashIcon, EmptyState, askForDelete, Switch, Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel, ChevronDownIcon, LoadingSpinnerHub
   },
   data() {
      return {
        packageDeleteAsk: false,
        query: null,
        queryLimitReached: false,
        sortName: "creationDesc",
        sortType: {
            order: "desc",
            type: "meta.created.date"
        },
        showStatusFilters: false,
        loading: false,
        enabledEdit: false,
        rerender: 0,
        deleteProductAsk: false,
        deleteAsk: false,
        deleteType: 'warehouse',
        dbListener: null,
        dbListenerSegment: null,
        newWarehouseName: '',
        newSegmentName: '',
        disabledAddButton: false,
        warehouses: [],
        segments: [],
        selected: [],
        option: {},
        index: null,
        modalData: {
            headerText: "Usuwanie magazynu",
            bodyText: "Czy na pewno chcesz usunąć wybrany magazyn? Dane zostaną permamentnie usunięte.",
            acceptButtonText: "Usuń"
        },
        toggleStorage: {},
        toggleSegment: {},
        latestSegment: null,
        toggleInformation: {},
        products: {},
        openAdditionalProducts: false,
        selectedProd: null,
        isEditable: false,
        selectedStorage: null,
        loadingSegment: false,
        alertModal: {
            open: false,
            data: {
                currentlyDeleted: {
                    total: null,
                    part: null,
                    variant: null,
                },
                headerText: "Usunięcie paczki z magazynu",
                bodyText: "Czy na pewno chcesz usunąć paczkę? Po usunięciu paczki, nie będzie można już jej przywrócić.",
                acceptButtonText: "Usuń"
            }
        },
        alertModalProductDelete: {
            open: false,
            data: {
                currentlyDeleted: null,
                headerText: "Usunięcie uszkodzonego produktu",
                bodyText: "Kiedy usuniesz uszkodzony produkt z magazynu, zostanie on również usunięty ze wszystkich segmentów. \n\n\n\n\n Czy jesteś pewny/a, że chcesz kontynuować?",
                acceptButtonText: "Usuń"
            }
        },
        unlockRefresh: false,
      }
   },
   created() {
        moment.locale('pl')
        this.moment = moment;
        
      if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.storage)
      {
        this.$router.push("/hub")
      }
      else
      {
        if(this.$store.state.userData.permissions.admin || this.$store.state.userData.permissions.storageEdit)
        {
            this.isEditable = true;
        }
      }
      this.dbListener = db.collection("Storage").orderBy("meta.created.unix", "desc")
      .onSnapshot((querySnapshot) => {
            this.warehouses = [];
            querySnapshot.forEach((doc) => {
               this.warehouses.push(doc.data());
            });
            for(let i=0; i<this.warehouses.length; i++)
            {
                this.toggleStorage[i] = {
                    open: false
                }
            }
      });
   },
   methods: {
    async checkStorage()
    {
        let segments = [];
        const getStorage = await db.collection('Storage').get();
        for(let i=0; i<getStorage.docs.length; i++)
        {
            let current = getStorage.docs[i].data();
            const getSegments = await db.collection('Storage').doc(current.id.toString()).collection('Segments').get();
            for(let y=0; y<getSegments.docs.length; y++)
            {
                let crnt = getSegments.docs[y].data();
                crnt.warehouse = current;
                if(Object.keys(crnt.products).length > 0)
                {
                    segments.push(crnt)
                }
            }
        };

        for(let i=0; i<segments.length; i++)
        {
            let orderIds = [];
            const products = Object.entries(segments[i].products)
            for(let y=0; y<products.length; y++)
            {
                orderIds.push(products[y][0])
            }
        }
    },
    async generateCSV()
    {
        try {
            this.$store.commit('setGlobalLoader',{
                show: true,
                head: "Generowanie pliku CSV...",
                subheader: `Nie zamykaj tego okna.`,
            });

            let segments = [];
            const getStorage = await db.collection('Storage').get();
            for(let i=0; i<getStorage.docs.length; i++)
            {
                let current = getStorage.docs[i].data();
                const getSegments = await db.collection('Storage').doc(current.id.toString()).collection('Segments').get();
                for(let y=0; y<getSegments.docs.length; y++)
                {
                    let crnt = getSegments.docs[y].data();
                    crnt.warehouse = current;
                    if(Object.keys(crnt.products).length > 0)
                    {
                        segments.push(crnt)
                    }
                }
            };
            const res = await axios.post(`${this.$store.state.herokuApiLink}/storage/${this.$store.state.herokuApiPath}/generateCSV`, {
                segments
            }, {
                headers: {
                    'x-api-key': process.env.VUE_APP_APIKEY,
                    'x-api-jwt-token': this.$store.state.userData.jwt
                }
            })
            if(res.data.success)
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Akcja zakończona sukcesem.",
                    subheader: `Plik CSV został pomyślnie wygenerowany.`,
                    success: true
                });
                this.$store.commit('resetGlobalLoader');
                const csvdata = this.csvmaker(res.data.payload.data);
                this.download(csvdata);
            }
            else
            {
                throw 'Błąd podczas generowania pliku CSV';
            }
        }
        catch (error) {
            console.log(error);
            this.$store.commit('resetGlobalLoader');
            this.$store.commit('setNotification',{
                show: true,
                head: "Akcja zakończona niepowodzeniem.",
                subheader: error,
                success: false
            });
        }
    },
    csvmaker(data)
    {
        let csvRows = [];
        for(let i=0;i<data.length; i++)
        {
            const values = Object.values(data[i]).join(';');
            csvRows.push(values)
        }
        return csvRows.join('\n')
    },
    download(data)
    {
        moment.locale('pl')
        let result = '';
        result = moment().format('LLL');
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', `${result}.csv`);
        a.click()
    },
    refreshData(val)
    {
        this.getAllSegmentsFromWarehouse(val);
        this.$store.commit('setNotification',{
            show: true,
            head: "Pomyślnie odświeżono magazyn.",
            subheader: '',
            success: true
        });
    },
    async deletePackage(type)
    {
        try {
            const res = await axios.post(`${this.$store.state.apiLink}/storage/delete`, {
                deleted: this.alertModal.data.currentlyDeleted,
                type,
                warehouse: this.selectedStorage,
                user: this.$store.state.userData
            })
            if(!res.data.success)
            {
                throw res.data.log;
            }
            else
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Akcja zakończona pomyślnie!",
                    subheader: `Usunięto ${type === 'all' ? 'całość.' : 'pojedyńczą sztukę.'}`,
                    success: true
                });
                this.alertModal.data.currentlyDeleted = {
                    total: null,
                    part: null,
                    variant: null,
                };
                setTimeout(() => {
                    this.openSegments(this.latestSegment.segment, this.latestSegment.index, this.latestSegment.warehouse)
                }, 1000);
            }
            this.openAdditionalProducts = false;
        }
        catch (error) {
            console.log(error);
            this.$store.commit('setNotification',{
                show: true,
                head: "Akcja zakończona niepowodzeniem!",
                subheader: 'Spróbuj ponownie później.',
                success: false
            });
            this.alertModal.data.currentlyDeleted = {
                total: null,
                part: null,
                variant: null,
            };
        }
    },
    cancelRemoveProductFromStorage()
    {
        this.alertModalProductDelete.open = false;
    },
    cancelRemoveFromStorage()
    {
        this.alertModal.open = false;
        // this.alertModal.data.currentlyDeleted.part = null;
        // this.alertModal.data.currentlyDeleted.variant = null;
        // this.alertModal.data.currentlyDeleted.total = null;
    },
    removeFromStorage(part, id, total, type)
    {
        if(type === 'part')
        {
            let oldTotal =  JSON.parse(JSON.stringify(total))
            delete oldTotal.variant
            this.alertModal.data.currentlyDeleted.part = part;
            this.alertModal.data.currentlyDeleted.variant = id;
            this.alertModal.data.currentlyDeleted.total = oldTotal;
            this.alertModal.open = true;
        }
        else if(type === 'product')
        {
            this.alertModalProductDelete.data.currentlyDeleted = id;
            this.alertModalProductDelete.open = true;
        }
    },
    showFilters(val)
    {
        this.showStatusFilters = !this.showStatusFilters
        if(!this.showStatusFilters){
            this.sortName = "creationDesc",
            this.sortType = {
                type: "meta.created.unix",
                order: "desc"
            }
            this.pickStatus(val)
        }
    },
    pickStatus(val)
    {
        // this.dbListener()
        this.getAllSegmentsFromWarehouse(val)
    },
    async printLabelWarehouse(warehouse)
    {
        var dd = {
            pageSize: 'A4',
            pageMargins: [ 30, 120, 30, 100 ],
        content: [
            {
                text: `${warehouse.name}`,
                color: '#333333',
                width: '*',
                fontSize: 32,
                bold: true,
                alignment: 'center',
                margin: [0,3,0,64]
            },
            { qr: `warehouse:[${warehouse.id}]`, fit: '400',  alignment: 'center', border: [false, false, false, false]},
        ],
        
            styles: {
            },
            defaultStyle: {
                columnGap: 20,
                // font: 'Quicksand',
            },
        };
        pdfMake.createPdf(dd).download(`qr_${warehouse.id}.pdf`);
    },
    async printLabelSegment(segment, warehouse)
    {
        var dd = {
            pageSize: 'A4',
            pageMargins: [ 30, 120, 30, 100 ],
            content: [
                {
                    text: `Segment: ${segment.name}`,
                    color: '#333333',
                    width: '*',
                    fontSize: 64,
                    bold: true,
                    alignment: 'center',
                },
                {
                    text: `${warehouse.name}`,
                    color: '#333333',
                    width: '*',
                    fontSize: 24,
                    bold: true,
                    alignment: 'center',
                    margin: [0,3,0,64]
                },
                { qr: `warehouse:[${warehouse.id}]:[${segment.id}]`, fit: '400',  alignment: 'center', border: [false, false, false, false]},
            ],
            styles: {
            },
            defaultStyle: {
                columnGap: 20,
                // font: 'Quicksand',
            },
        };
        pdfMake.createPdf(dd).download(`qr_${segment.id}.pdf`);
    },
    async getAllSegmentsFromWarehouse(val)
    {
        this.query = null;
        this.queryLimit = 25;
        if(this.dbListener !== null){
            this.dbListener()
            this.dbListener = null
            this.unlockRefresh = false;
        }
        this.query = db.collection('Storage')
        this.query = this.query.doc(val.toString()).collection('Segments')

        this.query = this.query.orderBy(`${this.sortType.type}`, `${this.sortType.order}`)

        this.query = this.query
        .limit(this.queryLimit)
        this.dbListener = this.query
        .onSnapshot((querySnapshot) => {
            this.segments = [];
            if(querySnapshot.docs.length<this.queryLimit)
            {
                this.queryLimitReached = true;
            }
            else
            {
                this.queryLimitReached = false;
            }
            querySnapshot.forEach((doc) => {
                let current = doc.data()
                this.segments.push(current);
            });
            for(let i=0; i<this.segments.length; i++)
            {
                this.toggleSegment[i] = {
                    open: false
                }
            }
        });
    },
    loadMoreSegments(val)
    {
        this.dbListener();
        this.continueListening(val);      
    },
    continueListening(val)
    {
        this.queryLimit = this.queryLimit + 25;
        this.dbListener = this.query.limit(this.queryLimit)
        .onSnapshot((querySnapshot) => {
            this.segments = [];
            if(querySnapshot.docs.length<this.queryLimit)
            {
                this.queryLimitReached = true;
            }
            else
            {
                this.queryLimitReached = false;
            }
            querySnapshot.forEach((doc) => {
                let current = doc.data()
                this.segments.push(current);
            });
            for(let i=0; i<this.segments.length; i++)
            {
                this.toggleSegment[i] = {
                    open: false
                }
            }
        });
    },
    openStorage(val, indx)
    {
        if(this.toggleStorage[indx].open)
        {
            this.toggleStorage[indx].open = false
            this.selectedStorage = null;
        }
        else
        {
            for (let i = 0; i < Object.keys(this.toggleStorage).length; i++) {
                this.toggleStorage[i].open = false
            }
            this.toggleStorage[indx].open = true
            this.selectedStorage = val;
        }
    },
    async deleteProductFromStorage(product)
    {
        try {
            const res = await axios.post(`${this.$store.state.apiLink}/storage/broken-product/delete`, {
                product: this.alertModalProductDelete.data.currentlyDeleted,
                user: this.$store.state.userData
            })
            if(res.data.success)
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Akcja zakończona pomyślnie!",
                    subheader: 'Usunięto uszkodzony produkt z magazynu.',
                    success: true
                });
            }
            else
            {
                throw res.data.log;
            }
        } catch (error)
        {
            this.$store.commit('setNotification',{
                show: true,
                head: "Akcja zakończona niepowodzeniem",
                subheader: 'Skontaktuj się z administratorem, jeśli problem będzie się powtarzał.',
                success: true
            });
            console.log(error);    
        }
    },
    changeSort()
    {
        if(this.sortName === "creationAsc")
        {
            this.sortType = {
                order: "asc",
                type: "meta.created.unix"
            }
        }
        if(this.sortName === "creationDesc")
        {
            this.sortType = {
                order: "desc",
                type: "meta.created.unix"
            }
        }
        // this.pickStatus()
    },
    async openSegments(segment, indx, warehouse)
    {
        this.loading = true;

        if(this.dbListenerSegment !== null){
            this.dbListenerSegment()
        }

        this.latestSegment = {
            warehouse: {
                id: warehouse.id,
            },
            segment: {
                id: segment.id,
            },
            index: indx
        }

        for (let i = 0; i < Object.keys(this.toggleSegment).length; i++) {
            this.toggleSegment[i].open = false
        }
        this.toggleSegment[indx].open = true

        this.dbListenerSegment = db.collection('Storage').doc(warehouse.id.toString()).collection('Segments').doc(segment.id.toString())
        .onSnapshot(async (doc) => {
            this.products = {};
            let data = doc.data();
            if(data.products != null)
            {
                let productIds = Object.keys(data.products)
                let productsRefs = [];
    
                for(let i=0; i<productIds.length; i++)
                {
                    let productsRef = db.collection("Products").doc(productIds[i])
                    productsRefs.push(productsRef)
                }
                let productsData = [];
                if(productsRefs.length > 0)
                {
                    for(let i=0; i<productsRefs.length; i++)
                    {
                        const product = await productsRefs[i].get();
                        if(product.exists)
                        {
                            productsData.push(product.data());
                        }
                    }

                    let variantsAll = {};
                    if(productsData.length > 0)
                    {
                        const prdStorage = Object.entries(data.products)
                        for(let i=0; i<prdStorage.length; i++)
                        {
                            if(Object.keys(prdStorage[i][1]).length > 0)
                            {
                                const prdEntries = Object.entries(prdStorage[i][1]).sort()
                                for(let y=0; y<prdEntries.length; y++)
                                {
                                    let str = prdEntries[y][0];
                                    let n = str.lastIndexOf('_');
                                    let result = str.slice(0, n);
                                    if(variantsAll[i] === undefined)
                                    {
                                        variantsAll[i] = {}
                                        variantsAll[i]['parts'] = {}
                                        variantsAll[i]['parts'][result] = {}
                                    }
                                    if(variantsAll[i]['parts'][result] === undefined)
                                    {
                                        variantsAll[i]['parts'][result] = {}
                                        variantsAll[i]['parts'][result][y] = {}
                                    }
                                    variantsAll[i]['parts'][result][y] = prdEntries[y][1]
                                    for(let p=0; p<productsData.length; p++)
                                    {
                                        if(productsData[p] != null)
                                        {
                                            productsData[p].isDeleted = false;
                                            const variants = Object.entries(productsData[p].variants)
                                            for(let v=0; v<variants.length; v++)
                                            {
                                                if(variants[v][0] === result)
                                                {
                                                    variantsAll[i]['parts'][result]['variant'] = variants[v][1]
                                                    break;
                                                }
                                            }
                                            if(productsData[p] != null)
                                            {
                                                if(productsData[p].id === prdStorage[i][0])
                                                {
                                                    productsData[p].isDeleted = false;
                                                    variantsAll[i]['product'] = productsData[p]
                                                }
                                            }
                                        }
                                    };
                                }
                            }
                        }
                        
                        for(let i=0; i<Object.keys(variantsAll).length; i++)
                        {
                            if(variantsAll[Object.keys(variantsAll)[i]]['product'] == null)
                            {
                                const parts = Object.entries(variantsAll[Object.keys(variantsAll)[i]]['parts'])
                                let str = parts[0][0];
                                let n = str.lastIndexOf('_');
                                let result = str.substring(n+1)
                                variantsAll[Object.keys(variantsAll)[i]]['product'] = {
                                    name: 'Usunięty produkt',
                                    isDeleted: true,
                                    model: 'Usunięty model',
                                    id: result,
                                    variants: {}
                                };
                            };
                        }
                        
                        this.products = variantsAll;
                        this.loading = false;
                    }
                    else
                    {
                        this.products = {};
                        this.loading = false;
                    }
                }
                else
                {
                    this.products = {};
                    this.loading = false;
                }
            }
        });
    },
    createCustomNameByLength(val)
    {
        let x = 'wariant';
        if(val === 1)
        {
            return `${val + ' ' + x}`;
        }
        else if((val === 2) <= 4)
        {
            return `${val + ' ' + x + 'y'}`;
        }
        else if(val >= 5)
        {
            return `${val + ' ' + x + 'ów'}`;
        }
    },
    async openAdditionalInformation(indx, prod)
    {
        this.openAdditionalProducts = false;
        this.selectedProd = prod;

        const variant = Object.entries(this.selectedProd)
        let prodId = '';
        for(let i=0; i<1; i++)
        {
            let str = variant[i][0];
            let n = str.lastIndexOf('_');
            let id = str.substring(n+1)
            let result = str.slice(0, n);
            prodId = id;
        }

        let productData = null;
        const product = await db.collection('Products').doc(prodId).get();
        productData = product.data();

        this.openAdditionalProducts = true;
    },
    async deleteFromDatabase()
    {
        if(!this.isEditable) throw `ERROR: No access to remove ${this.deleteType}`;
        try
        {
            if(this.deleteType === 'warehouse')
            {
                for(let i=0;i<this.selected.length;i++)
                {
                    await db.collection("Storage").doc(this.selected[i].warehouse.toString()).delete();
                }
            }
            else
            {
                for(let i=0;i<this.selected.length;i++)
                {
                    await db.collection("Storage").doc(this.selected[i].warehouse.toString()).collection('Segments').doc(this.selected[i].segment.toString()).delete();
                }
            }
            this.$store.commit('setNotification',{
                show: true,
                head: "Segment został usunięty!",
                subheader: `Segment nie będzie już dostępny.`,
                success: true
            }); 
            this.selected = [];
            this.rerender++;
        } catch (error) {
            this.$store.commit('setNotification',{
                show: true,
                head: "Akcja zakończona niepowodzeniem!",
                subheader: error,
                success: false
            }); 
            console.log(error.message);
        }
    },
    selectOption(id)
    {
        if(!this.selected.includes(id))
        {
            this.selected.push(id);
        }
        else
        {
            this.selected.splice(this.selected.indexOf(id),1);
        }
    },
    deleteOption(whId, id)
    {
        this.selected = [];
        this.option = {};
        this.option = {
            warehouse: whId,
            segment: id,
        }
        this.selected.push(this.option);
        this.deleteAsk = true
    },
    async addNewWarehouse()
    {
        if(this.newWarehouseName.length === 0)
        {
            return 0;
        }
        this.disabledAddButton = true
        try {
            let freeId = randomstring.generate({
                length: 4,
                charset: 'numeric'
            });
            
            const getStorage = await db.collection('Storage').where('id', '==', parseInt(freeId)).get();
            if(!getStorage.empty)
            {
                throw 'Nie umiem odnaleźć wolnego ID. Spróbuj ponownie później.';
            }
            
            await db.collection("Storage").doc(freeId.toString()).set({
                name: this.newWarehouseName,
                id: parseInt(freeId),
                meta: {
                    created: {
                        byUser: {
                            name: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                            id: this.$store.state.userData.id,
                            ref: db.collection("UsersData").doc(this.$store.state.userData.id)
                        },
                        asText: moment().format("YYYY-MM-DD").toString(),
                        unix: moment().unix(),
                        date: moment().toDate(),
                        dayOfWeek: moment().day(),
                        dayNum: moment().date(),
                        monthNum: moment().month() + 1,
                        year: moment().year(),
                        weekNum: moment().week()
                    },
                }
            });
            this.$store.commit('setNotification',{
                show: true,
                head: "Magazyn został utworzony!",
                subheader: `Magazyn ${this.newWarehouseName} został dodany do listy.`,
                success: true
            }); 
            this.disabledAddButton = false
            this.newWarehouseName = "";
        }
        catch(error)
        {
            this.$store.commit("resetGlobalLoader");
            console.log(error);
            this.$store.commit('setNotification',{
                show: true,
                head: "Kategoria nie może zostać utworzona!",
                subheader: error,
                success: false
            });   
        }
    },
    async addNewSegment(val)
    {
        if(this.newSegmentName.length === 0)
        {
            return 0;
        }
        this.disabledAddButton = true
        try {
            
            let freeId = randomstring.generate({
                length: 4,
                charset: 'numeric'
            });
            
            const getStorage = await db.collection('Storage').get();
            for(let i=0; i<getStorage.docs.length; i++)
            {
                const getSegments = await db.collection('Storage').doc(getStorage.docs[i].id.toString()).collection('Segments').where('id', '==', parseInt(freeId)).get();
                if(!getSegments.empty)
                {
                    throw 'Nie umiem odnaleźć wolnego ID. Spróbuj ponownie później.';
                    break;
                }
            };
            
            await db.collection("Storage").doc(val.toString()).collection('Segments').doc(freeId.toString()).set({
                name: this.newSegmentName,
                id: parseInt(freeId),
                meta: {
                    created: {
                        byUser: {
                            name: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                            id: this.$store.state.userData.id,
                            ref: db.collection("UsersData").doc(this.$store.state.userData.id)
                        },
                        asText: moment().format("YYYY-MM-DD").toString(),
                        unix: moment().unix(),
                        date: moment().toDate(),
                        dayOfWeek: moment().day(),
                        dayNum: moment().date(),
                        monthNum: moment().month() + 1,
                        year: moment().year(),
                        weekNum: moment().week()
                    },
                },
                products: {},
                productsId: []
            });
            this.$store.commit('setNotification',{
                show: true,
                head: "Segement został utworzony!",
                subheader: `Segment "${this.newSegmentName}" został dodany do listy.`,
                success: true
            }); 
            this.disabledAddButton = false
            this.newSegmentName = "";
        }
        catch(error)
        {
            this.$store.commit("resetGlobalLoader");
            console.log(error);
            this.$store.commit('setNotification',{
                show: true,
                head: "Kategoria nie może zostać utworzona!",
                subheader: error,
                success: false
            });   
        }
    }
   },
   watch: {
      enabledEdit()
      {
        this.selected = [];
      },
   },
   beforeUnmount()
   {
        if(this.dbListener !== null){
            this.dbListener();
        };
        if(this.dbListenerSegment !== null){
            this.dbListenerSegment()
        }
   }
}
</script>

<style lang="scss" scoped>

.fade-enter-active{
  transition: opacity 1s ease;
}
.fade-leave-active{
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}

</style>