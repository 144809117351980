<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div :class="[iconColor !== undefined ? `bg-${iconColor}-100` : 'bg-red-100']" class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationIcon :class="[iconColor !== undefined ? `text-${iconColor}-600` : 'text-red-600']" class="h-6 w-6 " aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900"> {{modalData.headerText}} </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">{{modalData.bodyText}}</p>
                </div>
              </div>
            </div>

            <div class="my-8 flex justify-center">
              <span class="uppercase font-semibold text-gray-500 mr-1 select-none">Wybrana paczka:</span>
              <span class="font-semibold text-indigo-600 select-text">{{modalData.currentlyDeleted.part.part}}</span>
            </div>

            <slot></slot>
              <div class="mt-5 flex flex-wrap gap-2">
                <div class="w-full inline-flex gap-4">
                  <button type="button" :disabled="buttonBlocked" :class="[buttonBlocked ? `opacity-40 hover:bg-${buttonColor}-500 cursor-not-allowed` : '', buttonColor !== undefined ? `bg-${buttonColor}-600 hover:bg-${buttonColor}-500 focus:ring-${buttonColor}-500` : '', 'bg-red-600 text-base font-medium hover:bg-red-700 focus:ring-red-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base flex-1 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto sm:text-sm transition duration-200']" @click="emitAccept('single')">Pojedyńcza sztuka (-1)</button>
                  <button type="button" :disabled="buttonBlocked" :class="[buttonBlocked ? `opacity-40 hover:bg-${buttonColor}-500 cursor-not-allowed` : '', buttonColor !== undefined ? `bg-${buttonColor}-600 hover:bg-${buttonColor}-500 focus:ring-${buttonColor}-500` : '', 'bg-red-600 text-base font-medium hover:bg-red-700 focus:ring-red-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base flex-1 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto sm:text-sm transition duration-200']" @click="emitAccept('all')">Całość (-{{modalData.currentlyDeleted.part.quantity}})</button>
                </div>
                <button type="button" class="w-full inline-flex flex-1 justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">
                  Anuluj
                </button>
              </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon } from '@heroicons/vue/outline'
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
  },
  props: {
    modalData: Object, 
    buttonColor: String, 
    iconColor: String,
    buttonBlocked: {
      type: Boolean,
      default: false
    },
    closeModalAfterAccept: {
      type: Boolean,
      default: true
    } 
  },
  data() {
    return {
      open: true
    }
  },
  methods: {
    emitClose()
    {
      this.$emit("closeModal");
    },
    emitAccept(type)
    {
      this.$emit("accept", type);
      if(this.closeModalAfterAccept) {
        this.$emit("closeModal");
      }
    },
    close()
    {
      this.emitClose();
      this.open = false
    }
  },
}
</script>
