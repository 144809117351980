<template>
    <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li class="flex">
            <div class="flex items-center">
            <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <router-link to="/dashboard/products" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł produkty</router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Atrybuty produktów</div>
            </div>
        </li>
        </ol>
    </nav>
    <router-link to="/dashboard/products" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>
    <div>
        <div class="mb-4">
            <div class="mt-1 flex w-full sm:4/5 md:w-3/5 lg:w-2/5 rounded-md">
                <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"> Nazwa </span>
                <input type="text" v-model="newAttributeName" class="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md border focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300" placeholder="Wpisz nazwę grupy" />
                <button type="button" @click.prevent="addNewGroup" :disabled="newAttributeName.length === 0 || this.disabledAddButton"  :class="{'transition ease-in-out duration-300 ml-4 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500': true, 'opacity-40 cursor-default': newAttributeName.length === 0 || this.disabledAddButton, 'hover:bg-blue-700': newAttributeName.length > 0 }">Utwórz grupę</button>
            </div>
        </div>
        <div>
            <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Grupy atrybutów:</h2>
            <div class="grid grid-cols-1 mt-2 gap-4 sm:grid-cols-4">
                <div v-for="attribute in groupsOfAttributes" :key="attribute.name" class="transition ease-in-out duration-300 text-gray-300 hover:text-gray-500 relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-4 py-4 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
                <div class="flex-shrink-0">
                    <!-- <img class="h-10 w-10 rounded-full" :src="person.imageUrl" alt="" /> -->
                    <div class="h-10 w-10 rounded-full text-white bg-green-400 flex justify-center items-center text-sm">{{attribute.name.substring(0,3).toUpperCase()}}</div>
                </div>
                <div class="min-w-0 flex-1">
                    <router-link :to="`/dashboard/products/attributes/modify?attrib=${attribute.id}`" class="focus:outline-none">
                    <span class="absolute inset-0" aria-hidden="true" />
                    <p class="text-sm font-medium text-gray-900 truncate">{{ attribute.name }}</p>
                    <p class="truncate text-sm text-gray-500">{{attribute.list.length}} wartoś{{attribute.list.length === 1 ? 'ć' : 'ci'}}</p>
                    </router-link>
                </div>
                <div class="flex-shrink-0">
                    <!-- <img class="h-10 w-10 rounded-full" :src="person.imageUrl" alt="" /> -->
                    <div class="rounded-full flex justify-center items-center">
                        <PencilAltIcon class="w-5 h-5" aria-hidden="true" />
                    </div>
                </div>
                </div>
            </div>
            <emptyState v-if="groupsOfAttributes.length === 0"></emptyState>
        </div>
    </div>
</template>

<script>
    //import emptyState from '@/components/EmptyState.vue';
    //import axios from 'axios';
    import moment from 'moment';
    import {db} from "@/firebase/gfbconf.js";
    //import { Switch } from '@headlessui/vue'
    import { PencilAltIcon,HomeIcon } from '@heroicons/vue/solid'
    import emptyState from '@/components/EmptyState.vue';

    export default {
        name: "Attributes",
        components: {PencilAltIcon, HomeIcon,emptyState},
        data()
        {
            return {
                enabledEdit: false,
                rerender: 0,
                deleteAsk: false,
                dbListener: null,
                newAttributeName: '',
                disabledAddButton: false,
                selected: [],
                modalData: {
                  headerText: "Delete group attribute",
                  bodyText: "Are you sure to delete selected attribute group? Data will be permanently removed.",
                  acceptButtonText: "Agree"
                },
                groupsOfAttributes: []
            }
        },
        created()
        {
            if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.products)
            {
                this.$router.push("/hub")
            }
            this.dbListener = db.collection("GroupsOfAttributes").orderBy("creationDate", "desc")
            .onSnapshot((querySnapshot) => {
                this.groupsOfAttributes = [];
                querySnapshot.forEach((doc) => {
                    this.groupsOfAttributes.push(doc.data());
                });
            });
        },
        methods:
        {
            async addNewGroup()
            {
                if(this.newAttributeName.length>0)
                {
                    this.disabledAddButton = true
                    const result = await db.collection("GroupsOfAttributes").add({
                        name: this.newAttributeName,
                        creationDate: moment().toDate(),
                        createdBy: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                        type: "product",
                        list: [],
                        id: ""
                    });
                    await db.collection("GroupsOfAttributes").doc(result.id).update({
                        'id': result.id
                    });
                    this.newAttributeName = "";
                    this.disabledAddButton = false
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Grupa atrybutów została utworzona!",
                        subheader: `Teraz możesz dodać wartości do utworzonej grupy.`,
                        success: true
                    });
                }
            }
        },
        watch: {
        },
        computed:
        {
        },
        beforeUnmount() {
            if(this.dbListener !== null){
                this.dbListener();
            }
        }
    }
</script>
<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>